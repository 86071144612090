<template>
  <div class="page">
    <div
      class="back-link"
      @click="$router.push(routeFrom && routeFrom.name ? routeFrom : { name: 'Datasets' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ (routeFrom && routeFrom.name === 'Datasets') || !routeFrom ? 'Jeux de données' : 'Jeu de donnée' }}
      </span>
    </div>
    <div class="top">
      <h2 style="margin-left: 1rem;">
        {{ currentDataset.display_name }}
      </h2>
      <div class="default-permission">
        <div>
          {{ $t('datasetPermissions.defaultAccess') }}
        </div>
        <div class="permissions">
          <b-overlay
            :show="busy"
            rounded="lg"
            opacity="0.6"
          >
            <template #overlay>
              <div class="d-flex align-items-center">
                <b-spinner
                  small
                  type="grow"
                  variant="secondary"
                />
                <b-spinner
                  type="grow"
                  variant="dark"
                  style="margin: 0 0.5em;"
                />
                <b-spinner
                  small
                  type="grow"
                  variant="secondary"
                />
              </div>
            </template>
            <button
              v-for="permission of permissions"
              :key="permission.level"
              :class="permission.level === currentDataset.default_permission_level ? 'btn-success' : 'btn-secondary'"
              :disabled="permission.level === currentDataset.default_permission_level"
              class="btn btn-sm"
              @click="
                askToChangePermissionsLevel({ level: permission.level, action: setDatasetDefaultPermissionLevel })
              "
            >
              {{ permission.label }}
            </button>
          </b-overlay>
        </div>
      </div>
    </div>
    <b-row
      class="row-cols-2"
      style="min-width: 1230px;"
    >
      <b-col class="col-10">
        <div class="actions">
          <Multiselect
            v-model="action"
            :options="actionsOptions"
            track-by="code"
            label="label"
            select-label=""
            selected-label=""
            deselect-label=""
            :searchable="false"
            :placeholder="$t('datasetPermissions.actions.placeholder')"
          >
            <span slot="noResult">
              {{ $t('datasetPermissions.actions.empty') }}
            </span>
            <span slot="noOptions">
              {{ $t('datasetPermissions.actions.empty') }}
            </span>
          </Multiselect>
          <b-button
            variant="primary"
            :disabled="action === null || !(selectedAll || selectedPermissions.length)"
            @click="askToChangePermissionsLevel({ level: null, action: sendDatasetPermissionAction })"
          >
            {{ $t('buttons.send') }}
          </b-button>
        </div>
        <SearchablePaginatedTable
          :name="'datasetPermissionsTable'"
          :loading="loadingTable"
          :searchable="true"
          :search-placeholder="$t('datasetPermissions.searchPlaceholder')"
          :search-function="SEARCH_DATASET_USERGROUPS_PERMISSIONS"
          :fields="fields"
          :rows="rows"
          :count="currentUsergroupsPermissionsCount"
          :sort-function="GET_DATASET_USERGROUPS_PERMISSIONS"
          :selectable="true"
          :allow-deletion="false"
          :all-selected="areAllPermissionsSelected"
          @select="setSelectedPermissions"
          @select-all="setSelectedAll"
          @sorted="setTableSorted"
          @change-page="changeTablePage"
        />
      </b-col>
      <b-col class="col-2 filters-col">
        <ul class="list-group">
          <li class="list-group-item active">
            {{ $t('datasetPermissions.filters.label') }}
          </li>
          <li class="list-group-item">
            {{ $t('datasetPermissions.filters.groupTypes.label') }}
          </li>
          <li
            class="list-group-item"
            style="padding: 0;"
          >
            <div class="btn-group-vertical">
              <b-button
                v-for="(filter, id) in groupFilters"
                :key="id"
                :pressed.sync="filter.state"
                variant="outline-primary"
                @click="setGroupFilters(filter)"
              >
                {{ filter.label }}
              </b-button>
            </div>
          </li>
          <li class="list-group-item">
            {{ $t('datasetPermissions.filters.organisationTypes.label') }}
          </li>
          <li
            class="list-group-item"
            style="padding: 0;"
          >
            <Multiselect
              v-model="organisationTypeFilter"
              class="filter-dropdown"
              :options="organisationsTypes"
              track-by="codename"
              label="display_name"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('datasetPermissions.filters.organisationTypes.placeholder')"
            />
          </li>
          <li class="list-group-item">
            {{ $t('datasetPermissions.filters.accessLevel.label') }}
          </li>
          <li
            class="list-group-item"
            style="padding: 0;"
          >
            <div class="btn-group-vertical">
              <b-button
                v-for="(filter, id) in accessLevelFilters"
                :key="id"
                :pressed.sync="filter.state"
                variant="outline-primary"
                @click="setAccessLevelFilters(filter)"
              >
                {{ filter.label }}
              </b-button>
            </div>
          </li>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';
import Multiselect from 'vue-multiselect';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'DatasetPermissions',

  components: {
    SearchablePaginatedTable,
    Multiselect
  },

  data() {
    return {
      routeFrom: null,
      loadingTable: false,
      busy: false,
      permissionLevelToChange:  null,
      action: null,
      selectedAll: false,
      organisationTypeFilter: null,
      tableSorted: {
        direction: null,
        field: null
      },
      areAllPermissionsSelected: false
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentUsergroupsPermissionsCount',
      'currentUsergroupsPermissions',
      'datasetsList',
      'currentDataset',
      'isPermissionsListSearched',
      'selectedPermissions'
    ]),

    ...mapState('organisations', [
      'organisationsTypes',
    ]),

    ...mapState('usergroups', [
      'usergroupsTypes',
    ]),

    actionsOptions() {
      return [
        {
          label: this.$t('datasetPermissions.actions.none'),
          code: 0
        },
        {
          label: this.$t('datasetPermissions.actions.search'),
          code: 1
        },
        {
          label: this.$t('datasetPermissions.actions.consultation'),
          code: 2
        },
        {
          label: this.$t('datasetPermissions.actions.download'),
          code: 3
        }
      ];
    },

    permissions() {
      return [
        { label: this.$t('datasetPermissions.permissions.private'), level: 0 },
        { label: this.$t('datasetPermissions.permissions.search'), level: 1 },
        { label: this.$t('datasetPermissions.permissions.consultation'), level: 2 },
        { label: this.$t('datasetPermissions.permissions.download'), level: 3 }
      ];
    },
    groupFilters() {
      return [
        {
          label: this.$t('datasetPermissions.filters.groupTypes.all'),
          type: '',
          state: true,
        },
        {
          label: this.$t('datasetPermissions.filters.groupTypes.organisations'),
          type: 'organisation',
          state: false,
        },
        {
          label: this.$t('datasetPermissions.filters.groupTypes.usergroups'),
          type: 'user-group',
          state: false,
        },
        {
          label: this.$t('datasetPermissions.filters.groupTypes.spheres'),
          type: 'group-of-organisation',
          state: false,
        }
      ];
    },
    accessLevelFilters() {
      return [
        {
          label: this.$t('datasetPermissions.filters.accessLevel.all'),
          access: '',
          state: true,
        },
        {
          label: this.$t('datasetPermissions.filters.accessLevel.none'),
          access: 0,
          state: false,
        },
        {
          label: this.$t('datasetPermissions.filters.accessLevel.search'),
          access: 1,
          state: false,
        },
        {
          label: this.$t('datasetPermissions.filters.accessLevel.consultation'),
          access: 2,
          state: false,
        },
        {
          label: this.$t('datasetPermissions.filters.accessLevel.download'),
          access: 3,
          state: false,
        },
      ];
    },

    fields() {
      return [
        {
          key: this.$t('datasetPermissions.fields.name'),
          apiName: 'usergroup__display_name',
          sortable: true,
          width: '18%',
          centered: false
        },
        {
          key: this.$t('datasetPermissions.fields.type'),
          apiName: 'usergroup__usergroup_type__display_name',
          sortable: true,
          width: '10%',
          centered: false
        },
        {
          key: this.$t('datasetPermissions.fields.organisationType'),
          apiName: 'usergroup__organisation__organisation_type',
          sortable: true,
          width: '15%',
          centered: false
        },
        {
          key: this.$t('datasetPermissions.fields.signedConvention'),
          sortable: false,
          width: '12%',
          centered: true
        },
        {
          key: this.$t('datasetPermissions.fields.convention'),
          sortable: false,
          width: '8%',
          centered: true
        },
        {
          key: this.$t('datasetPermissions.fields.accessLevel'),
          apiName: 'level',
          sortable: true,
          width: '13%',
          centered: true
        }
      ];
    },
    rows() {
      return this.currentUsergroupsPermissions.map(el => {
        return {
          id: el.id,
          Nom: el.usergroup.display_name,
          Type: el.usergroup.usergroup_type ? el.usergroup.usergroup_type.display_name : null,
          'Type d\'organisation':
            el.usergroup.organisation &&
            el.usergroup.organisation.organisation_type &&
            this.organisationsTypes.find(e => e.id === el.usergroup.organisation.organisation_type) ?
              this.organisationsTypes.find(e => e.id === el.usergroup.organisation.organisation_type).display_name :
              null,
          'Convention signée': el.usergroup.organisation ? el.usergroup.organisation.agreement_signed : null,
          Convention: el.usergroup.organisation && el.usergroup.organisation.agreement_file ?
            el.usergroup.organisation.agreement_file.url : null,
          'Niveau d\'accès': el.level //this.accessLevelFilters.find(level => el.level === level.access).label
        };
      });
    }
  },

  watch: {
    organisationTypeFilter(newValue) {
      if (newValue) {
        this.PRE_SET_USERGROUPS_PERMISSIONS_FILTERS({
          filter: 'organisation_type_id',
          value: newValue.id
        });
        this.getDatasetPermissions();
      } else {
        this.PRE_RESET_USERGROUPS_PERMISSIONS_FILTERS('organisation_type_id');
        this.getDatasetPermissions();
      }
    },
    selectedPermissions: {
      deep: true,
      handler(newValue) {
        const sortedSelectedPermissions = newValue.map(el => el.id).sort();
        const sortedCurrentPermissions = this.currentUsergroupsPermissions.map(el => el.id).sort();
        if (
          sortedSelectedPermissions.length === sortedCurrentPermissions.length &&
          sortedSelectedPermissions.every(function(value, index) {
            return value === sortedCurrentPermissions[index];
          })
        ) {
          this.areAllPermissionsSelected = true;
        } else {
          this.areAllPermissionsSelected = false;
        }
      }
    },
  },

  created() {
    this.GET_DATASET_DETAIL(this.$route.params.id);
    if (
      (this.currentUsergroupsPermissions.length === 0 ||
      this.currentUsergroupsPermissions[0].dataset.toString() !== this.$route.params.id)
      && !this.isPermissionsListSearched
    ) {
      this.getDatasetPermissions();
    }
    if (this.organisationsTypes.length === 0) {
      this.GET_ORGANISATIONS_TYPES();
    }
    if (this.usergroupsTypes.length === 0) {
      this.GET_USERGROUPS_TYPES();
    }
    this.SET_TABLE({
      name: 'datasetPermissionsTable',
      table: {
        loading: false,
        currentPage: 1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from;
    });
  },

  beforeRouteLeave(to, from, next) {
    this.RESET_SELECTED_PERMISSIONS();
    next();
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapMutations('datasets', [
      'SET_SELECTED_PERMISSIONS',
      'RESET_SELECTED_PERMISSIONS'
    ]),
    ...mapActions('datasets', [
      'PRE_SET_USERGROUPS_PERMISSIONS_FILTERS',
      'PRE_REMOVE_USERGROUPS_PERMISSIONS_FILTERS',
      'PRE_RESET_USERGROUPS_PERMISSIONS_FILTERS',
      'GET_DATASET_USERGROUPS_PERMISSIONS',
      'SET_DATASET_USERGROUPS_PERMISSIONS',
      'SEARCH_DATASET_USERGROUPS_PERMISSIONS',
      'GET_DATASET_DETAIL',
      'PATCH_DATASET'
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATIONS_TYPES'
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUPS_TYPES'
    ]),

    getDatasetPermissions(page) {
      this.loadingTable = true;
      this.GET_DATASET_USERGROUPS_PERMISSIONS({
        ...this.tableSorted,
        id : this.$route.params.id || this.currentUsergroupsPermissions[0].dataset.toString(),
        page: page
      })
        .then(() => {
          this.loadingTable = false;
        })
        .catch((err) => {
          console.error(err);
          this.loadingTable = false;
        });
    },

    askToChangePermissionsLevel(e) {
      this.permissionLevelToChange = e.level;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.changeDatasetPermissions.title'),
        content: this.$t('modals.changeDatasetPermissions.content'),
        trigger: e.action
      });
    },

    setDatasetDefaultPermissionLevel() {
      const data = {
        default_permission_level: this.permissionLevelToChange
      };
      this.busy = true;
      this.loadingTable = true;
      this.PATCH_DATASET({
        id: this.currentDataset.id,
        data: data
      })
        .then(() => {
          this.GET_DATASET_DETAIL(this.$route.params.id)
            .then(() => {
              this.busy = false;
              this.getDatasetPermissions();
              this.permissionLevelToChange = null;
            })
            .catch((err) => {
              console.error(err);
              this.busy = false;
              this.loadingTable = false;
              this.permissionLevelToChange = null;
            });
        })
        .catch((err) => {
          console.error(err);
          this.busy = false;
          this.loadingTable = false;
          this.permissionLevelToChange = null;
        });
    },

    sendDatasetPermissionAction() {
      const data = {
        dataset_pk: [this.currentUsergroupsPermissions[0].dataset],
        ...(this.selectedPermissions.length > 0 || this.selectedAll) && {
          pk : this.selectedAll ? 'all' : this.selectedPermissions.map(el => el.id)
        }
      };
      this.loadingTable = true;
      this.SET_DATASET_USERGROUPS_PERMISSIONS({ level: this.action.code, data: data })
        .then(() => {
          this.GET_DATASET_USERGROUPS_PERMISSIONS({
            ...this.tableSorted,
            id : this.$route.params.id || this.currentUsergroupsPermissions[0].dataset.toString(),
          })
            .then(() => {
              this.RESET_SELECTED_PERMISSIONS();
              this.loadingTable = false;
              this.permissionLevelToChange = null;
            })
            .catch((err) => {
              console.error(err);
              this.RESET_SELECTED_PERMISSIONS();
              this.loadingTable = false;
              this.permissionLevelToChange = null;
            });
        })
        .catch((err) => {
          console.error(err);
          this.loadingTable = false;
          this.permissionLevelToChange = null;
        });
    },

    changeTablePage(e) {
      this.getDatasetPermissions(e);
    },

    setTableSorted(e) {
      this.tableSorted = e;
    },

    setGroupFilters(e) {
      const FILTER = 'usergroup_type_id';
      if (e.label === 'Tous' && !e.state) {
        this.groupFilters[0].state = true;

      } else {
        if (e.label !== 'Tous' && e.state) {
          this.groupFilters[0].state = false;
        }
        if (e.label !== 'Tous' && !e.state) {
          if (this.groupFilters.every(el => el.state === false)) {
            this.groupFilters.find(el => el.label === e.label).state = true;
          }
        }
        if (e.label === 'Tous' && e.state) {
          this.groupFilters.forEach((el, i) => {
            if (i !== 0) {
              el.state = false;
            }
          });
        }
        if (this.groupFilters.slice(1).every(el => el.state === true)) {
          this.groupFilters[0].state = true;
          this.groupFilters.forEach((el, i) => {
            if (i !== 0) {
              el.state = false;
            }
          });
        }
      }

      for (const item of this.groupFilters) {
        if (item.state) {
          this.PRE_SET_USERGROUPS_PERMISSIONS_FILTERS({
            filter: FILTER,
            value: item.type !== '' ? this.usergroupsTypes.find(el => el.codename === item.type).id : ''
          });
        } else {
          this.PRE_REMOVE_USERGROUPS_PERMISSIONS_FILTERS({
            filter: FILTER,
            value: item.type !== '' ? this.usergroupsTypes.find(el => el.codename === item.type).id : ''
          });
        }
      }

      this.getDatasetPermissions();
    },

    setAccessLevelFilters(e) {
      const FILTER = 'level';

      this.accessLevelFilters.filter(el => el.label !== e.label).forEach(el => el.state = !e.state);
      this.PRE_SET_USERGROUPS_PERMISSIONS_FILTERS({
        filter: FILTER,
        value: [e.access]
      });

      this.getDatasetPermissions();
    },

    setSelectedPermissions(e) {
      this.SET_SELECTED_PERMISSIONS(e);
    },

    setSelectedAll(e) {
      this.selectedAll = e;
    },
  }
};
</script>

<style lang="less" scoped>

.list-group-item {
  padding: 8px 12px;
  font-size: 0.9em;
  font-weight: 600;
  .btn-group-vertical {
    width: 100%;
    .btn {
      border: none;
      text-align: start;
      font-size: 0.9em;
    }
  }
}

.list-group-item.active {
  padding: 8px 12px;
  background-color: #343a40;
  font-size: 1em;
  font-weight: bold;
}

.default-permission {
  display: flex;
  margin: 1em;
  font-weight: 600;
  color: rgb(87, 87, 87);
  .permissions {
    margin-left: 1em;
    display: flex;
    flex-direction: row;
    button {
      border-radius: 10px;
      font-size: 0.75em;
      font-weight: 600;
      opacity: 0.9;
      box-shadow: 0 0 1px 1px #ccc;
      margin-right: 0.5em;
    }
  }
}

.actions {
  margin-left: 2em;
  font-weight: 600;
  display: flex;
  align-items: center;
  .multiselect {
    width: 340px;
    margin-right: 1em;
  }
}

@media screen and (max-width: 1500px) {
  .search-bar {
    margin-top: 0 !important;
    width: 28.7em;
  }
}
@media screen and (min-width: 1500px) {
  .search-bar {
    margin-top: 0 !important;
    width: 58.5%;
  }
}

</style>
